define("ember-bootstrap/templates/components/bs-accordion/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mPvMzGl7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[27,\"hash\",null,[[\"title\",\"body\"],[[27,\"component\",[[23,[\"titleComponent\"]]],[[\"collapsed\",\"disabled\",\"onClick\"],[[23,[\"collapsed\"]],[23,[\"disabled\"]],[27,\"action\",[[22,0,[]],[23,[\"onClick\"]],[23,[\"value\"]]],null]]]],[27,\"component\",[[23,[\"bodyComponent\"]]],[[\"collapsed\"],[[23,[\"collapsed\"]]]]]]]]]],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"component\",[[23,[\"titleComponent\"]]],[[\"collapsed\",\"disabled\",\"onClick\"],[[23,[\"collapsed\"]],[23,[\"disabled\"]],[27,\"action\",[[22,0,[]],[23,[\"onClick\"]],[23,[\"value\"]]],null]]],{\"statements\":[[0,\"    \"],[1,[21,\"title\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[23,[\"bodyComponent\"]]],[[\"collapsed\"],[[23,[\"collapsed\"]]]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-accordion/item.hbs"
    }
  });
  _exports.default = _default;
});