define("ember-power-calendar/components/power-calendar-multiple", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "ember-power-calendar/components/power-calendar", "ember-power-calendar-utils"], function (_exports, _toConsumableArray2, _powerCalendar, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _powerCalendar.default.extend({
    daysComponent: 'power-calendar-multiple/days',
    _calendarType: 'multiple',
    // CPs
    selected: Ember.computed({
      get: function get() {
        return undefined;
      },
      set: function set(_, v) {
        return Array.isArray(v) ? v.map(_emberPowerCalendarUtils.normalizeDate) : v;
      }
    }),
    currentCenter: Ember.computed('center', function () {
      var center = this.get('center');
      if (!center) {
        center = (this.get('selected') || [])[0] || this.get('powerCalendarService').getDate();
      }
      return (0, _emberPowerCalendarUtils.normalizeDate)(center);
    }),
    // Actions
    actions: {
      select: function select(day, calendar, e) {
        var action = this.get("onSelect");
        if (action) {
          action(this._buildCollection(day), calendar, e);
        }
      }
    },
    // Methods
    _buildCollection: function _buildCollection(day) {
      var selected = this.get("publicAPI.selected") || [];
      var values = [];
      var index = -1;
      for (var i = 0; i < selected.length; i++) {
        if ((0, _emberPowerCalendarUtils.isSame)(day.date, selected[i], "day")) {
          index = i;
          break;
        }
      }
      if (index === -1) {
        values = [].concat((0, _toConsumableArray2.default)(selected), [day.date]);
      } else {
        values = selected.slice(0, index).concat(selected.slice(index + 1));
      }
      return (0, _emberPowerCalendarUtils.normalizeMultipleActionValue)({
        date: values
      });
    }
  });
  _exports.default = _default;
});