define('ember-remodal/services/remodal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    modal: null,
    title: Ember.computed.alias('ember-remodal.title'),
    text: Ember.computed.alias('ember-remodal.text'),
    confirmButton: Ember.computed.alias('ember-remodal.confirmButton'),
    cancelButton: Ember.computed.alias('ember-remodal.cancelButton'),
    disableNativeClose: Ember.computed.alias('ember-remodal.disableNativeClose'),
    disableForeground: Ember.computed.alias('ember-remodal.disableForeground'),
    disableAnimation: Ember.computed.alias('ember-remodal.disableAnimation'),
    buttonClasses: Ember.computed.alias('ember-remodal.buttonClasses'),
    modifier: Ember.computed.alias('ember-remodal.modifier'),
    closeOnEscape: Ember.computed.alias('ember-remodal.closeOnEscape'),
    closeOnCancel: Ember.computed.alias('ember-remodal.closeOnCancel'),
    closeOnConfirm: Ember.computed.alias('ember-remodal.closeOnConfirm'),
    hashTracking: Ember.computed.alias('ember-remodal.hashTracking'),
    closeOnOutsideClick: Ember.computed.alias('ember-remodal.closeOnOutsideClick'),

    open: function open() {
      var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'ember-remodal';
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      var modal = this.get(name);

      if (modal) {
        if (opts) {
          modal.setProperties(opts);
        }

        return modal.open();
      } else {
        this._modalNotSetError(name);
      }
    },
    close: function close() {
      var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'ember-remodal';

      var modal = this.get(name);

      if (modal) {
        return modal.close();
      } else {
        this._modalNotSetError(name);
      }
    },
    _modalNotSetError: function _modalNotSetError(name) {
      (false && !(false) && Ember.assert('The requested modal, "' + name + '" can not be opened because it is not rendered in the current route. In order to use ember-remodal as a service, an instance of {{ember-remodal}} must currently be rendered, with "forService=true". Try putting it in your application template.'));
    }
  });
});