define('ember-cli-file-picker/components/file-picker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      observer = Ember.observer,
      bind = Ember.run.bind,
      htmlSafe = Ember.String.htmlSafe,
      assert = Ember.assert,
      $ = Ember.$;
  exports.default = Component.extend({
    classNames: ['file-picker'],
    classNameBindings: ['multiple:multiple:single'],
    accept: '*',
    multiple: false,
    preview: true,
    dropzone: true,
    progress: true,
    hideFileInput: true,
    readAs: 'readAsFile',
    selectOnClick: true,
    count: 0,
    errors: [],
    imagePreviewSrc: null,

    progressStyle: computed('progressValue', function () {
      var width = this.get('progressValue') || 0;

      return htmlSafe('width: ' + width + '%;');
    }),

    /**
     * When the component got inserted
     */
    didInsertElement: function didInsertElement() {
      if (this.get('hideFileInput')) {
        this.hideInput();
      }
      this.hidePreview();
      this.hideProgress();

      this.$('.file-picker__input').on('change', bind(this, 'filesSelected'));
    },

    willDestroyElement: function willDestroyElement() {
      this.$('.file-picker__input').off('change', bind(this, 'filesSelected'));
    },

    /**
     * When the file input changed (a file got selected)
     * @param  {Event} event The file change event
     */
    filesSelected: function filesSelected(event) {
      var files = event.target.files;
      if (files.length) {
        this.handleFiles(files);
      } else {
        this.clearPreview();
      }
    },

    handleFiles: function handleFiles(files) {
      var _this = this;

      if (typeof this.filesAreValid === 'function') {
        if (!this.filesAreValid(files)) {
          return;
        }
      }

      if (this.get('preview')) {
        this.updatePreview(files);
      }

      if (this.get('multiple')) {
        this.sendAction('filesLoaded', files);
      } else {
        if (this.get('readAs') === 'readAsFile') {
          this.sendAction('fileLoaded', files[0]);
        } else {
          this.readFile(files[0], this.get('readAs')).then(function (file) {
            _this.sendAction('fileLoaded', file);
          });
        }
      }

      if (!this.get('hideFileInput')) {
        this.$('.file-picker__input').val(null);
      }
    },

    /**
     * Update preview
     * @param  {Array} files The selected files
     */
    updatePreview: function updatePreview(files) {
      if (this.get('multiple')) {
        // TODO
      } else {
        //this.clearPreview();
        //this.$('.file-picker__progress').show();

        this.readFile(files[0], 'readAsDataURL').then(bind(this, 'addPreviewImage'));

        //this.$('.file-picker__dropzone').hide();
      }

      //this.$('.file-picker__preview').show();
    },

    addPreviewImage: function addPreviewImage(file) {
      // var image = this.$(
      //   '<img src="' + file.data + '" class="file-picker__preview__image ' +
      //   (this.get('multiple') ? 'multiple' : 'single') + '">');

      this.set('imagePreviewSrc', file.data);

      //this.hideProgress();
      //this.$('.file-picker__preview').append(image);
    },

    /**
     * Reads a file
     * @param {File} file A file
     * @param {String} readAs One of
     *  - readAsArrayBuffer
     *  - readAsBinaryString
     *  - readAsDataURL
     *  - readAsText
     * @return {Promise}
     */
    readFile: function readFile(file, readAs) {
      var _this2 = this;

      var reader = new FileReader();

      assert('readAs method "' + readAs + '" not implemented', reader[readAs] && readAs !== 'abort');

      return new Ember.RSVP.Promise(function (resolve, reject) {
        reader.onload = function (event) {
          resolve({
            // TODO rename to file / breaking change
            filename: file.name,
            type: file.type,
            data: event.target.result,
            size: file.size
          });
        };

        reader.onabort = function () {
          reject({
            event: 'onabort'
          });
        };

        reader.onerror = function (error) {
          reject({
            event: 'onerror',
            error: error
          });
        };

        reader.onprogress = function (event) {
          _this2.set('progressValue', event.loaded / event.total * 100);
        };

        reader[readAs](file);
      });
    },

    hideInput: function hideInput() {
      this.$('.file-picker__input').hide();
    },

    hidePreview: function hidePreview() {
      this.$('.file-picker__preview').hide();
    },

    hideProgress: function hideProgress() {
      this.$('.file-picker__progress').hide();
    },

    clearPreview: function clearPreview() {
      this.$('.file-picker__preview').html('');
      this.hidePreview();
      this.$('.file-picker__dropzone').show();

      // reset
      this.set('removePreview', false);
    },

    removePreviewDidChange: observer('removePreview', function () {
      if (this.get('removePreview')) {
        this.clearPreview();
      }
    }),

    // handles DOM events
    // Trigger a input click to open file dialog
    click: function click(event) {
      if (this.get('selectOnClick') === true) {
        if (!$(event.target).hasClass('file-picker__input')) {
          this.$('.file-picker__input').trigger('click');
        }
      }
    },
    /* Drag'n'Drop events */
    dragOver: function dragOver(event) {
      if (event.preventDefault) {
        event.preventDefault();
      }
      event.dataTransfer.dropEffect = 'copy';
    },
    drop: function drop(event) {
      if (event.preventDefault) {
        event.preventDefault();
      }

      this.handleFiles(event.dataTransfer.files);
      this.set('count', 0);
      this.$().removeClass('over');
    },
    dragEnter: function dragEnter(event) {
      if (event.preventDefault) {
        event.preventDefault();
      }
      if (!this.get('multiple')) {
        this.clearPreview();
      }
      var count = this.incrementProperty('count');
      if (count === 1) {
        this.$().addClass('over');
      }
    },
    dragLeave: function dragLeave(event) {
      if (event.preventDefault) {
        event.preventDefault();
      }
      var count = this.decrementProperty('count');
      if (count === 0) {
        this.$().removeClass('over');
      }
    }
  });
});