define('ember-remodal/components/ember-remodal', ['exports', 'ember-remodal/templates/components/ember-remodal'], function (exports, _emberRemodal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _emberRemodal.default,
    warn: function warn() {
      return _warn;
    },

    remodal: Ember.inject.service(),
    attributeBindings: ['dataTestId:data-test-id'],
    classNames: ['remodal-component'],
    tagName: 'span',
    name: 'ember-remodal',
    modifier: '',
    modal: null,
    options: null,
    closeOnEscape: true,
    closeOnCancel: true,
    closeOnConfirm: true,
    hashTracking: false,
    closeOnOutsideClick: true,
    forService: false,
    disableForeground: false,
    disableAnimation: false,
    disableNativeClose: Ember.computed.reads('disableForeground'),
    erOpenButton: false,
    erCancelButton: false,
    erConfirmButton: false,

    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, '_setProperties');
      Ember.run.scheduleOnce('afterRender', this, '_registerObservers');
      Ember.run.scheduleOnce('afterRender', this, '_checkForDeprecations');
      Ember.run.scheduleOnce('afterRender', this, '_checkForTestingEnv');
    },
    willDestroyElement: function willDestroyElement() {
      Ember.run.scheduleOnce('destroy', this, '_destroyDomElements');
      Ember.run.scheduleOnce('destroy', this, '_deregisterObservers');
    },


    modalId: Ember.computed('elementId', {
      get: function get() {
        return '[data-remodal-id=' + this.get('elementId') + ']';
      }
    }),

    animationState: Ember.computed('disableAnimation', {
      get: function get() {
        if (this.get('disableAnimation')) {
          return 'disable-animation';
        } else {
          return '';
        }
      }
    }),

    openDidFire: Ember.on('opened', function () {
      this.sendAction('onOpen');
    }),

    closeDidFire: Ember.on('closed', function () {
      this.sendAction('onClose');
    }),

    open: function open() {
      return this._promiseAction('open');
    },
    close: function close() {
      var _this = this;

      if (this.get('modal')) {
        return this._promiseAction('close');
      } else {
        this.get('warn')('ember-remodal: You called "close" on a modal that has not yet been opened. This is not a big deal, but I thought you should know. The returned promise will immediately resolve.', false, { id: 'ember-remodal.close-called-on-unitialized-modal' });
        return new Ember.RSVP.Promise(function (resolve) {
          return resolve(_this);
        });
      }
    },
    _promiseAction: function _promiseAction(action) {
      var _this2 = this;

      var modal = this.get('modalId');
      var actionName = this._pastTense(action);

      this.send(action);

      return new Ember.RSVP.Promise(function (resolve) {
        Ember.$(document).one(actionName, modal, function () {
          return resolve(_this2);
        });
      });
    },
    _pastTense: function _pastTense(action) {
      if (action[action.length - 1] === 'e') {
        return action + 'd';
      } else {
        return action + 'ed';
      }
    },
    _setProperties: function _setProperties() {
      var opts = this.get('options');

      if (opts) {
        this.setProperties(opts);
      }

      if (this.get('forService')) {
        this.get('remodal').set(this.get('name'), this);
      }
    },
    _registerObservers: function _registerObservers() {
      var _this3 = this;

      var modal = this.get('modalId');
      Ember.$(document).on('opened', modal, function () {
        return Ember.sendEvent(_this3, 'opened');
      });
      Ember.$(document).on('closed', modal, function () {
        return Ember.sendEvent(_this3, 'closed');
      });
    },
    _deregisterObservers: function _deregisterObservers() {
      var modal = this.get('modalId');
      Ember.$(document).off('opened', modal);
      Ember.$(document).off('closed', modal);
    },
    _destroyDomElements: function _destroyDomElements() {
      var modal = this.get('modal');

      if (modal) {
        modal.destroy();
      }
    },
    _createInstanceAndOpen: function _createInstanceAndOpen() {
      var config = this._getConfig();
      var appendTo = config && config.APP.rootElement ? config.APP.rootElement : '.ember-application';

      var modal = Ember.$(this.get('modalId')).remodal({
        appendTo: appendTo,
        hashTracking: this.get('hashTracking'),
        closeOnOutsideClick: this.get('closeOnOutsideClick'),
        closeOnEscape: this.get('closeOnEscape'),
        modifier: this.get('modifier')
      });

      this.set('modal', modal);
      this.send('open');
    },
    _checkForDeprecations: function _checkForDeprecations() {
      // Deprecations go here
    },
    _checkForTestingEnv: function _checkForTestingEnv() {
      var config = this._getConfig();

      if (config) {
        var env = config.environment;
        var remodalConfig = config['ember-remodal'];
        var disableAnimation = void 0;

        if (remodalConfig) {
          disableAnimation = remodalConfig.disableAnimationWhileTesting;
        }

        if (disableAnimation && env === 'test') {
          this.set('disableAnimation', true);
        }
      }
    },
    _getConfig: function _getConfig() {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    },
    _openModal: function _openModal() {
      this.get('modal').open();
    },
    _closeModal: function _closeModal() {
      this.get('modal').close();
    },
    _closeOnCondition: function _closeOnCondition(condition) {
      this.sendAction('on' + condition);

      if (this.get('closeOn' + condition)) {
        this.send('close');
      }
    },


    actions: {
      confirm: function confirm() {
        this._closeOnCondition('Confirm');
      },
      cancel: function cancel() {
        this._closeOnCondition('Cancel');
      },
      open: function open() {
        if (this.get('modal')) {
          Ember.run.scheduleOnce('afterRender', this, '_openModal');
        } else {
          Ember.run.scheduleOnce('afterRender', this, '_createInstanceAndOpen');
        }
      },
      close: function close() {
        Ember.run.next(this, '_closeModal');
      }
    }
  });
});