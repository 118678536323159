define('ember-m-image/components/m-image', ['exports', 'ember-m-image/templates/components/m-image', 'ember-m-image/utils/m-image-dom-loader'], function (exports, _mImage, _mImageDomLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    layout: _mImage.default,
    tagName: 'img',
    attributeBindings: ['_imageSrc:src', 'alt', 'crossorigin', 'height', 'longdesc', 'sizes', 'width'],
    classNameBindings: ['imageStateCss'],
    imageStateCss: '',

    // @private
    _domLoader: Ember.computed(function () {
      return (0, _mImageDomLoader.default)();
    }),

    // @private
    _updateState: function _updateState(css, src) {
      this.set('imageStateCss', css);
      this.set('_imageSrc', src);
    },


    // @private
    _onSuccess: function _onSuccess(_imageSrc) {
      this._updateState('complete', _imageSrc);
      this.sendAction('onLoadComplete');
    },


    // @private
    _onError: function _onError() {
      this._updateState('error', this.get('fallbackImageSrc'));
      this.sendAction('onLoadError');
    },


    // @private
    _loadAlternateImage: function _loadAlternateImage() {
      return this.get('_domLoader')(this.get('fallbackImageSrc')).then(this._onError.bind(this), this._onError.bind(this));
    },


    // @public
    loadImage: function loadImage() {
      var src = this.get('src');

      if (!Ember.isBlank(src)) {
        this.sendAction('onLoadStart');
        this._updateState('loading', this.get('preloaderImageSrc'));

        return (this.get('loader') || this.get('_domLoader'))(src).then(this._onSuccess.bind(this), this._loadAlternateImage.bind(this));
      }
    },


    // @private
    imageSrcObserver: Ember.observer('src', function () {
      this.loadImage();
    }),

    // @private
    didInsertElement: function didInsertElement() {
      this.loadImage();
    }
  });
});