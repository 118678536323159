define('ember-perfect-scroll/mixins/perfect-scroll-controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      isEmpty = Ember.isEmpty,
      isPresent = Ember.isPresent;
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      this.initializePerfecScrollArray();
    },
    initializePerfecScrollArray: function initializePerfecScrollArray() {
      this.set('perfectScrolls', Ember.A());
    },
    getPerfectScroll: function getPerfectScroll(perfectScrollId) {
      if (isEmpty(this.get('perfectScrolls'))) {
        return null;
      }

      if (isEmpty(perfectScrollId)) {
        return this.get('perfectScrolls')[0];
      }

      return this.get('perfectScrolls').filter(function (item) {
        return get(item, 'element.id') === perfectScrollId;
      })[0];
    },
    updatePerfectScroll: function updatePerfectScroll(perfectScrollId) {
      var perfectScroll = this.getPerfectScroll(perfectScrollId);

      if (isPresent(perfectScroll)) {

        perfectScroll.update();
      }
    },
    performScroll: function performScroll(scrollLeft, scrollTop, perfectScrollId) {
      var perfectScroll = this.getPerfectScroll(perfectScrollId);

      if (isEmpty(perfectScroll)) {
        return;
      }

      if (isPresent(scrollLeft)) {
        perfectScroll.element.scrollLeft = scrollLeft;
      }

      if (isPresent(scrollTop)) {
        perfectScroll.element.scrollTop = scrollTop;
      }
    },


    actions: {
      lifeCycleEventOccurred: function lifeCycleEventOccurred(perfectScroll, eventName) {
        if (eventName === 'initialized') {
          get(this, 'perfectScrolls').pushObject(perfectScroll);
        } else {
          get(this, 'perfectScrolls').removeObject(perfectScroll);
        }
      }
    }
  });
});