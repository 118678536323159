define("ember-bootstrap/templates/components/bs-modal/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Jf4742rN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"hasSubmitButton\"]]],null,{\"statements\":[[0,\"    \"],[4,\"component\",[[23,[\"buttonComponent\"]]],[[\"onClick\"],[[27,\"action\",[[22,0,[]],[23,[\"onClose\"]]],null]]],{\"statements\":[[1,[21,\"closeTitle\"],false]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"component\",[[23,[\"buttonComponent\"]]],[[\"type\",\"onClick\",\"disabled\"],[[23,[\"submitButtonType\"]],[27,\"action\",[[22,0,[]],[23,[\"onSubmit\"]]],null],[23,[\"submitDisabled\"]]]],{\"statements\":[[1,[21,\"submitTitle\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[4,\"component\",[[23,[\"buttonComponent\"]]],[[\"type\",\"onClick\"],[\"primary\",[27,\"action\",[[22,0,[]],[23,[\"onClose\"]]],null]]],{\"statements\":[[1,[21,\"closeTitle\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/footer.hbs"
    }
  });
  _exports.default = _default;
});