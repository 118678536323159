define("ember-stripe-elements/components/stripe-card", ["exports", "ember-stripe-elements/components/stripe-element", "ember-stripe-elements/templates/components/stripe-card"], function (_exports, _stripeElement, _stripeCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _stripeElement.default.extend({
    layout: _stripeCard.default,
    classNames: ['ember-stripe-card'],
    type: 'card'
  });
  _exports.default = _default;
});