define("ember-power-calendar/components/power-calendar-multiple/days", ["exports", "ember-power-calendar/components/power-calendar/days", "ember-power-calendar/utils/computed-fallback-if-undefined", "ember-power-calendar-utils"], function (_exports, _days, _computedFallbackIfUndefined, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _days.default.extend({
    maxLength: (0, _computedFallbackIfUndefined.default)(Infinity),
    // Methods
    dayIsSelected: function dayIsSelected(date) {
      var calendar = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('calendar');
      var selected = calendar.selected || [];
      return selected.some(function (d) {
        return (0, _emberPowerCalendarUtils.isSame)(date, d, 'day');
      });
    },
    dayIsDisabled: function dayIsDisabled(date) {
      var numSelected = this.get('calendar.selected.length') || 0;
      var maxLength = this.get('maxLength') || Infinity;
      return this._super.apply(this, arguments) || numSelected >= maxLength && !this.dayIsSelected(date);
    }
  });
  _exports.default = _default;
});