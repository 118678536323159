define("ember-basic-dropdown-hover/components/basic-dropdown-hover", ["exports", "ember-basic-dropdown-hover/templates/components/basic-dropdown-hover"], function (_exports, _basicDropdownHover) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _basicDropdownHover.default,
    tagName: '',
    delay: 300,
    openDelay: Ember.computed.oneWay('delay'),
    closeDelay: Ember.computed.oneWay('delay'),
    actions: {
      open: function open(dropdown) {
        var _this = this;
        if (this.closeTimer) {
          Ember.run.cancel(this.closeTimer);
          this.closeTimer = null;
        } else {
          var openFn = function openFn() {
            _this.openTimer = null;
            dropdown.actions.open();
          };
          var openDelay = this.get('openDelay');
          if (openDelay) {
            this.openTimer = Ember.run.later(openFn, openDelay);
          } else {
            openFn();
          }
        }
      },
      close: function close(dropdown) {
        var _this2 = this;
        if (this.openTimer) {
          Ember.run.cancel(this.openTimer);
          this.openTimer = null;
        } else {
          var closeFn = function closeFn() {
            _this2.closeTimer = null;
            dropdown.actions.close();
          };
          var closeDelay = this.get('closeDelay');
          if (closeDelay) {
            this.closeTimer = Ember.run.later(closeFn, closeDelay);
          } else {
            closeFn();
          }
        }
      },
      prevent: function prevent() {
        return false;
      }
    }
  });
  _exports.default = _default;
});