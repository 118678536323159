define("ember-basic-dropdown-hover/templates/components/basic-dropdown-hover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "RjS1XiM2",
    "block": "{\"symbols\":[\"dd\",\"&default\"],\"statements\":[[4,\"basic-dropdown\",null,[[\"renderInPlace\",\"verticalPosition\",\"horizontalPosition\",\"matchTriggerWidth\",\"triggerComponent\",\"contentComponent\",\"calculatePosition\",\"onOpen\",\"onClose\",\"onFocus\",\"calculateInPlacePosition\"],[[23,[\"renderInPlace\"]],[23,[\"verticalPosition\"]],[23,[\"horizontalPosition\"]],[23,[\"matchTriggerWidth\"]],[23,[\"triggerComponent\"]],[23,[\"contentComponent\"]],[23,[\"calculatePosition\"]],[23,[\"onOpen\"]],[23,[\"onClose\"]],[23,[\"onFocus\"]],[23,[\"calculateInPlacePosition\"]]]],{\"statements\":[[0,\"  \"],[14,2,[[27,\"assign\",[[22,1,[]],[27,\"hash\",null,[[\"trigger\",\"content\"],[[27,\"component\",[[22,1,[\"trigger\"]]],[[\"onMouseDown\",\"onMouseEnter\",\"onMouseLeave\"],[[27,\"action\",[[22,0,[]],\"prevent\"],null],[27,\"action\",[[22,0,[]],\"open\"],null],[27,\"action\",[[22,0,[]],\"close\"],null]]]],[27,\"component\",[[22,1,[\"content\"]]],[[\"onMouseEnter\",\"onMouseLeave\"],[[27,\"action\",[[22,0,[]],\"open\"],null],[27,\"action\",[[22,0,[]],\"close\"],null]]]]]]]],null]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-basic-dropdown-hover/templates/components/basic-dropdown-hover.hbs"
    }
  });
  _exports.default = _default;
});