define("ember-popper/templates/components/ember-popper-targeting-parent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3Mp9Cc/E",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[27,\"unbound\",[[23,[\"_parentFinder\"]]],null],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"renderInPlace\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"id\",[21,\"id\"]],[12,\"class\",[21,\"class\"]],[12,\"hidden\",[21,\"hidden\"]],[12,\"role\",[21,\"ariaRole\"]],[9],[0,\"\\n    \"],[14,1,[[27,\"hash\",null,[[\"disableEventListeners\",\"enableEventListeners\",\"scheduleUpdate\",\"update\"],[[27,\"action\",[[22,0,[]],\"disableEventListeners\"],null],[27,\"action\",[[22,0,[]],\"enableEventListeners\"],null],[27,\"action\",[[22,0,[]],\"scheduleUpdate\"],null],[27,\"action\",[[22,0,[]],\"update\"],null]]]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"in-element\",[[23,[\"_popperContainer\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"id\",[21,\"id\"]],[12,\"class\",[21,\"class\"]],[12,\"hidden\",[21,\"hidden\"]],[12,\"role\",[21,\"ariaRole\"]],[9],[0,\"\\n    \"],[14,1,[[27,\"hash\",null,[[\"disableEventListeners\",\"enableEventListeners\",\"scheduleUpdate\",\"update\"],[[27,\"action\",[[22,0,[]],\"disableEventListeners\"],null],[27,\"action\",[[22,0,[]],\"enableEventListeners\"],null],[27,\"action\",[[22,0,[]],\"scheduleUpdate\"],null],[27,\"action\",[[22,0,[]],\"update\"],null]]]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-popper/templates/components/ember-popper-targeting-parent.hbs"
    }
  });
  _exports.default = _default;
});