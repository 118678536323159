define("ember-power-datepicker/templates/components/power-datepicker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jaEpe1DX",
    "block": "{\"symbols\":[\"dd\",\"cal\",\"datepicker\",\"&default\"],\"statements\":[[4,\"basic-dropdown\",null,[[\"disabled\",\"onOpen\",\"onClose\",\"onFocus\",\"destination\",\"initiallyOpened\",\"horizontalPosition\",\"calculatePosition\",\"verticalPosition\",\"renderInPlace\"],[[23,[\"disabled\"]],[23,[\"onOpen\"]],[23,[\"onClose\"]],[23,[\"onFocus\"]],[23,[\"destination\"]],[23,[\"initiallyOpened\"]],[23,[\"horizontalPosition\"]],[23,[\"calculatePosition\"]],[23,[\"verticalPosition\"]],[23,[\"renderInPlace\"]]]],{\"statements\":[[4,\"power-calendar\",null,[[\"tagName\",\"center\",\"locale\",\"onCenterChange\",\"onSelect\",\"selected\"],[\"\",[23,[\"center\"]],[23,[\"locale\"]],[23,[\"onCenterChange\"]],[27,\"action\",[[22,0,[]],\"onSelect\"],null],[23,[\"selected\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"with\",[[27,\"hash\",null,[[\"uniqueId\",\"calendarUniqueId\",\"isOpen\",\"disabled\",\"selected\",\"loading\",\"center\",\"locale\",\"actions\"],[[22,1,[\"uniqueId\"]],[22,2,[\"uniqueId\"]],[22,1,[\"isOpen\"]],[22,1,[\"disabled\"]],[22,2,[\"selected\"]],[22,2,[\"loading\"]],[22,2,[\"center\"]],[22,2,[\"locale\"]],[27,\"assign\",[[22,1,[\"actions\"]],[22,2,[\"actions\"]]],null]]]]],null,{\"statements\":[[0,\"      \"],[14,4,[[27,\"assign\",[[22,3,[]],[27,\"hash\",null,[[\"trigger\",\"content\",\"nav\",\"days\"],[[27,\"component\",[[22,1,[\"trigger\"]]],[[\"dropdown\",\"classNames\"],[[22,3,[]],\"ember-power-datepicker-trigger\"]]],[27,\"component\",[[22,1,[\"content\"]]],[[\"dropdown\",\"defaultClass\"],[[22,3,[]],\"ember-power-datepicker-content\"]]],[27,\"component\",[[22,2,[\"nav\"]]],[[\"calendar\"],[[22,3,[]]]]],[27,\"component\",[[22,2,[\"days\"]]],[[\"calendar\",\"data-power-calendar-id\"],[[22,3,[]],[22,3,[\"calendarUniqueId\"]]]]]]]]],null]]],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-datepicker/templates/components/power-datepicker.hbs"
    }
  });
  _exports.default = _default;
});