define("ember-search-flow/components/search-flow", ["exports", "ember-search-flow/templates/components/search-flow"], function (_exports, _searchFlow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _searchFlow.default,
    classNames: ['search-flow'],
    searchLabel: 'Add Filter',
    clearLabel: 'Clear Filters',
    maxFilters: null,
    defaultParameterValues: {
      allowMultiple: true,
      remoteOptions: false,
      contains: false,
      sort: true,
      suggested: false
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.processQueries();
      this.set('isSelectingParameter', false);
    },
    getParameter: function getParameter(parameter) {
      return this.get('parameters').find(function (param) {
        return parameter.toLowerCase() === param.name.toLowerCase();
      });
    },
    processQueries: Ember.observer('query', 'parameters', function () {
      var _this = this;
      if (this.get('queryGeneratedByComponent')) {
        this.set('queryGeneratedByComponent', false);
        return;
      }
      var filters = this.set('filters', Ember.A([]));
      Object.keys(this.get('query')).forEach(function (key) {
        var keys = [key];
        var isContains = false;
        if (key === 'contains') {
          keys = Object.keys(_this.get('query.contains'));
          isContains = true;
        }
        keys.forEach(function (key) {
          var values = _this.get('query');
          if (isContains) {
            values = values['contains'];
          }
          values = values[key];
          if (!Ember.isArray(values)) {
            values = [values];
          }
          values.forEach(function (value) {
            var parameter = _this.getParameter(key);
            if (parameter && (!isContains || isContains && parameter.contains)) {
              var filter = Ember.Object.create({
                parameter: Ember.Object.create(parameter),
                value: value
              });
              if (isContains) {
                filter.set('isContains', true);
              }
              filter.parameters = Ember.assign({}, _this.get('defaultParameterValues'), filter.parameters);
              filters.pushObject(filter);
            }
          });
        });
      });
    }),
    availableParameters: Ember.computed('parameters', 'filters.[]', 'filters.@each.parameter', 'parameters.@each.suggested', function () {
      var _this2 = this;
      return this.get('parameters').reject(function (parameter) {
        return !parameter.name || !parameter.title || parameter.allowMultiple === false && _this2.get('filters').find(function (filter) {
          return filter.get('parameter.name') === parameter.name;
        });
      });
    }),
    suggestedParameters: Ember.computed('availableParameters', function () {
      return this.get('availableParameters').filterBy('suggested');
    }),
    canClearAll: Ember.computed('filters.[]', function () {
      return this.get('filters.length') > 1;
    }),
    isParameterAvailable: function isParameterAvailable(parameter) {
      if (!parameter) {
        return true;
      }
      return this.get('availableParameters').findBy('name', parameter.name);
    },
    setOnQuery: function setOnQuery(isContains, query, path, value) {
      if (isContains) {
        query.contains[path] = value;
      } else {
        query[path] = value;
      }
    },
    getOnQuery: function getOnQuery(isContains, query, path) {
      if (isContains) {
        return query.contains[path];
      }
      return query[path];
    },
    generateQuery: function generateQuery() {
      var _this3 = this;
      var query = {};
      this.get('filters').forEach(function (filter) {
        var queryPath = filter.parameter.name;
        if (filter.get('isContains') && !query.contains) {
          query.contains = {};
        }
        var queryItem = _this3.getOnQuery(filter.get('isContains'), query, queryPath);
        if (queryItem) {
          if (!Ember.isArray(queryItem)) {
            if (queryItem === filter.value) {
              return;
            }
            queryItem = [queryItem];
          }
          if (queryItem.includes(filter.value)) {
            return;
          }
          queryItem.push(filter.value);
          _this3.setOnQuery(filter.get('isContains'), query, queryPath, queryItem);
        } else {
          _this3.setOnQuery(filter.get('isContains'), query, queryPath, filter.value);
        }
      });
      this.set('queryGeneratedByComponent', true);
      this.set('query', query);
      if (this.get('onQueryUpdated')) {
        this.get('onQueryUpdated')(query);
      }
    },
    canAddNewFilter: Ember.computed('isSelectingParameter', 'filters.[]', 'filters.@each.isFocused', 'maxFilters', function () {
      if (this.get('isSelectingParameter')) {
        return false;
      }
      if (typeof this.get('maxFilters') === 'number' && this.get('maxFilters') <= this.get('filters.length')) {
        return false;
      }
      return !this.get('filters').isAny('isFocused');
    }),
    actions: {
      newFilter: function newFilter(event) {
        if (event.which === 13) {
          // Enter key
          // Must prevent the filter from auto selecting an option
          this.set('didHitEnter', true);
        }
        this.set('isSelectingParameter', true);
      },
      newFilterWithParameter: function newFilterWithParameter(parameter) {
        if (!this.isParameterAvailable(parameter)) {
          return;
        }
        var filter = Ember.Object.create({
          parameter: Ember.Object.create(parameter),
          value: ''
        });
        filter.parameter = Ember.assign({}, this.get('defaultParameterValues'), filter.parameter);
        this.get('filters').pushObject(filter);
        this.set('isSelectingParameter', false);
      },
      setParameterToFilter: function setParameterToFilter(parameter, filter) {
        filter.set('parameter', Ember.Object.create(parameter));
      },
      clearFilters: function clearFilters() {
        this.set('filters', Ember.A([]));
        this.generateQuery();
      },
      removeFilter: function removeFilter(query) {
        this.get('filters').removeObject(query);
        this.generateQuery();
      },
      inputBlurred: function inputBlurred(isNewParameter, filter, shouldRemoveFilter) {
        if (isNewParameter) {
          this.set('isSelectingParameter', false);
          return;
        }
        if (!filter.value || shouldRemoveFilter) {
          this.get('filters').removeObject(filter);
        }
        this.set('addingNewfilter', false);
        this.generateQuery();
      }
    }
  });
  _exports.default = _default;
});