define("ember-cli-clipboard/components/copy-button", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "ember-cli-clipboard/templates/components/copy-button"], function (_exports, _toConsumableArray2, _copyButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CLIPBOARD_EVENTS = ['success', 'error'];
  var _default = Ember.Component.extend({
    layout: _copyButton.default,
    tagName: 'button',
    classNames: ['copy-btn'],
    attributeBindings: ['clipboardText:data-clipboard-text', 'clipboardTarget:data-clipboard-target', 'clipboardAction:data-clipboard-action', 'buttonType:type', 'disabled', 'aria-label', 'title'],
    /**
     * @property {String} buttonType - type attribute for button element
     */
    buttonType: 'button',
    /**
     * @property {Boolean} disabled - disabled state for button element
     */
    disabled: false,
    /**
     * If true - scope event listener to this element
     * If false - scope event listener to document.body (ClipboardJS)
     * @property {Boolean} delegateClickEvent
     */
    delegateClickEvent: true,
    /**
     * Creates new `ClipboardJS` instance
     * @method _createClipboard
     * @private
     * @returns {Object} newly created ClipboardJS object
     */
    _createClipboard: function _createClipboard() {
      var text = this.clipboardText;
      var trigger = this.delegateClickEvent ? "#".concat(this.elementId) : this.element;
      return new window.ClipboardJS(trigger, {
        text: typeof text === 'function' ? text : undefined
      });
    },
    /**
     * Registers Ember Actions with ClipboardJS events
     * @method _registerActions
     * @private
     * @param {Object} clipboard - ClipboardJS object
     * @returns {Void}
     */
    _registerActions: function _registerActions(clipboard) {
      var _arguments = arguments,
        _this = this;
      CLIPBOARD_EVENTS.forEach(function (event) {
        clipboard.on(event, function () {
          if (!_this.disabled) {
            var action = _this[event] || function () {};
            if (typeof action === 'string') {
              // eslint-disable-next-line ember/closure-actions
              _this.sendAction.apply(_this, [action].concat((0, _toConsumableArray2.default)(_arguments)));
            } else {
              action.apply(void 0, (0, _toConsumableArray2.default)(_arguments));
            }
          }
        });
      });
    },
    /**
     * Registers ClipboardJS object with component
     * @method _registerClipboard
     * @private
     * @returns {Void}
     */
    _registerClipboard: function _registerClipboard() {
      if (this.clipboard) {
        this.clipboard.destroy();
      }
      var clipboard = this._createClipboard();
      this._registerActions(clipboard);
      Ember.set(this, 'clipboard', clipboard);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._registerClipboard();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this._registerClipboard();
    },
    willDestroyElement: function willDestroyElement() {
      if (this.clipboard) {
        this.clipboard.destroy();
      }
    }
  });
  _exports.default = _default;
});