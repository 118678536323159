define('ember-did-change-attrs/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function isEqual(key, a, b) {
    return a === b;
  }

  exports.default = Ember.Mixin.create({
    _didChangeAttrsBuffer: null, //this tracks previous state of any `trackAttrChanges`
    didChangeAttrsConfig: [], //attributes to track

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var buffer = this.get('_didChangeAttrsBuffer');

      if (buffer === null) {
        //first run
        var config = this.get('didChangeAttrsConfig');
        var trackedAttrs = config.attrs;
        var initialValues = {};

        for (var i = 0; i < trackedAttrs.length; i++) {
          var key = trackedAttrs[i];
          initialValues[key] = this.get(key);
        }

        this.set('_didChangeAttrsBuffer', initialValues);
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var config = this.get('didChangeAttrsConfig');
      var equalityFn = config.isEqual || isEqual;

      var trackedAttrs = config.attrs;
      var oldValues = this.get('_didChangeAttrsBuffer');
      var changes = {};

      for (var i = 0; i < trackedAttrs.length; i++) {
        var key = trackedAttrs[i];
        var current = this.get(key);
        var previous = oldValues[key];

        if (!equalityFn(key, previous, current)) {
          changes[key] = { previous: previous, current: current };
          oldValues[key] = current;
        }
      }

      if (Object.keys(changes).length > 0) {
        this.didChangeAttrs(changes);
      }
    }
  });
});