define("ember-bootstrap/templates/components/bs-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UGza0S7e",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"item\",\"link-to\",\"dropdown\"],[[27,\"component\",[[23,[\"itemComponent\"]]],null],[27,\"component\",[[23,[\"linkToComponent\"]]],null],[27,\"component\",[[23,[\"dropdownComponent\"]]],[[\"inNav\",\"tagName\"],[true,\"li\"]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-nav.hbs"
    }
  });
  _exports.default = _default;
});