define('ember-text-highlight/-private/indices-implementation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (value, query, options) {
    var indices = findIndicesOf(query, value, options.caseSensitive);

    // If we couldn't find any match, return input untouched
    if (Ember.isEmpty(indices)) {
      return Ember.String.htmlSafe(value);
    }

    var queryLength = query.length;
    var indicesCount = indices.length;
    var valueLength = value.length;

    var result = '';
    var lastIndex = void 0;
    for (var i = 0; i < indicesCount; i++) {
      var index = lastIndex = indices[i];

      // Find and add unmatched characters before this match
      var matchPrefixStartIndex = findMatchPrefixStartIndex(indices, i, queryLength);
      result += value.slice(matchPrefixStartIndex, index);

      // Add wrapped match
      result += '<span class="mark">' + value.slice(index, index + queryLength) + '</span>';
    }

    // If applicable, add remaining characters after the last match
    if (hasRemainingUnmatchedCharacters(lastIndex, queryLength, valueLength)) {
      result += value.slice(lastIndex + queryLength, valueLength);
    }

    return Ember.String.htmlSafe(result);
  };

  function findMatchPrefixStartIndex(indices, i, queryLength) {
    return i === 0 ? 0 : indices[i - 1] + queryLength;
  }

  /**
   * Indices Implementation
   *
   * Highlight `value` input String with String.indexOf() implementation that can be more performant on many short
   * Strings than the approach with a Regular Expression.
   *
   * @param {String} value The template string to highlight matches if any
   * @param {String} query The string to search in `value`
   * @param {Object} options
   * @param {Boolean} options.caseSensitive
   *
   * @returns {Ember.String.htmlSafe}
   */


  function hasRemainingUnmatchedCharacters(lastIndex, queryLength, valueLength) {
    var lastMatchEndIndex = lastIndex + queryLength;
    return lastMatchEndIndex < valueLength;
  }

  /**
   * Find and return all indices of string `query` in `source`.
   *
   * @param {String} query
   * @param {String} source
   * @param {Boolean} [caseSensitive=false]
   * @returns {Number[]}
   * @private
   */
  function findIndicesOf(query, source, caseSensitive) {
    var index = void 0,
        startIndex = 0;

    var queryLength = query.length;
    var indices = [];

    if (!caseSensitive) {
      query = query.toLowerCase();
      source = source.toLowerCase();
    }

    while ((index = source.indexOf(query, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + queryLength;
    }

    return indices;
  }
});