define("ember-perfect-scroll/components/perfect-scroll/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wfD9xC8s",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"id\",[28,[[21,\"eId\"]]]],[11,\"class\",\"ps-content\"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-perfect-scroll/components/perfect-scroll/template.hbs"
    }
  });
  _exports.default = _default;
});