define("ember-bootstrap/templates/components/bs-modal/header/close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "j9kGUdCW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"aria-hidden\",\"true\"],[9],[0,\"×\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/header/close.hbs"
    }
  });
  _exports.default = _default;
});