define("liquid-fire/templates/components/liquid-versions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IUTPXwy6",
    "block": "{\"symbols\":[\"version\",\"&default\"],\"statements\":[[4,\"each\",[[23,[\"versions\"]]],null,{\"statements\":[[4,\"if\",[[27,\"lf-or\",[[23,[\"renderWhenFalse\"]],[22,1,[\"value\"]]],null]],null,{\"statements\":[[4,\"liquid-child\",null,[[\"version\",\"liquidChildDidRender\",\"class\"],[[22,1,[]],[27,\"action\",[[22,0,[]],\"childDidRender\"],null],[23,[\"class\"]]]],{\"statements\":[[14,2,[[22,1,[\"value\"]]]]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "liquid-fire/templates/components/liquid-versions.hbs"
    }
  });
  _exports.default = _default;
});