define("ember-bootstrap/templates/components/bs-form/element/help-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zZVUZt6L",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"text\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/help-text.hbs"
    }
  });
  _exports.default = _default;
});