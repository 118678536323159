define("ember-search-flow/components/search-flow/dropdown", ["exports", "ember-search-flow/templates/components/search-flow/dropdown"], function (_exports, _dropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _dropdown.default,
    classNames: ['search-flow_dropdown__bg'],
    hasNoOption: Ember.computed('options', function () {
      return this.get('options.length') === 0;
    }),
    actions: {
      optionHovered: function optionHovered(option) {
        this.set('activeOption.isActive', false);
        Ember.set(option, 'isActive', true);
      },
      optionClicked: function optionClicked() {
        this.get('selectOption')();
      }
    }
  });
  _exports.default = _default;
});