define("ember-composable-helpers/helpers/append", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "ember-composable-helpers/-private/create-multi-array-helper"], function (_exports, _toConsumableArray2, _createMultiArrayHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.append = append;
  _exports.default = void 0;
  function append() {
    var _Ember;
    for (var _len = arguments.length, dependentKeys = new Array(_len), _key = 0; _key < _len; _key++) {
      dependentKeys[_key] = arguments[_key];
    }
    dependentKeys = dependentKeys || [];
    var arrayKeys = dependentKeys.map(function (dependentKey) {
      return "".concat(dependentKey, ".[]");
    });
    return (_Ember = Ember).computed.apply(_Ember, (0, _toConsumableArray2.default)(arrayKeys).concat([function () {
      var _this = this,
        _ref;
      var array = dependentKeys.map(function (dependentKey) {
        var value = Ember.get(_this, dependentKey);
        return Ember.isArray(value) ? value.toArray() : [value];
      });
      return (_ref = []).concat.apply(_ref, (0, _toConsumableArray2.default)(array));
    }]));
  }
  var _default = (0, _createMultiArrayHelper.default)(append);
  _exports.default = _default;
});