define('ember-text-highlight/helpers/text-highlight', ['exports', 'ember-text-highlight/-private/env-detection', 'ember-text-highlight/-private/indices-implementation', 'ember-text-highlight/-private/regex-implementation'], function (exports, _envDetection, _indicesImplementation, _regexImplementation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.MAX_VALUE_LENGTH_FOR_INDICES_IMPL = undefined;


  // RegEx Implementation exceeds Indices Implementation on large texts independent of environment
  var MAX_VALUE_LENGTH_FOR_INDICES_IMPL = exports.MAX_VALUE_LENGTH_FOR_INDICES_IMPL = 250;

  var DEFAULT_OPTIONS = {
    caseSensitive: false
  };

  /**
   * Text Highlight Helper
   *
   * Mark all occurences of a string in input string with <span class="mark">
   *
   * Usage:
   * {{{text-highlight value query=myQuery}}}
   *
   * Expected Input:
   * `params` = ['valueString']
   * `options` = {query: 'queryString'}
   *
   * Picks the best implementation concerning input and environment.
   */
  exports.default = Ember.Helper.helper(function () {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_OPTIONS;

    var value = findValueAndTransformToStringIfApplicable(params);
    var query = options.query;

    // validate and transform input
    var queryIsValid = Ember.typeOf(query) === 'string' && !Ember.isEmpty(query.trim());
    var valueIsValid = Ember.typeOf(value) === 'string' && !Ember.isEmpty(value.trim());

    if (!queryIsValid) {
      return Ember.String.htmlSafe(value);
    }

    if (!valueIsValid) {
      return '';
    }

    //options = _object.merge(DEFAULT_OPTIONS, _lang.clone(options));
    options = Ember.assign(DEFAULT_OPTIONS, options);

    // as of a certain value length, regular expressions will likely start to exceed the performance of our indices
    // implementation
    if (value.length > MAX_VALUE_LENGTH_FOR_INDICES_IMPL) {
      return (0, _regexImplementation.default)(value, query, options);
    }

    // pick the best implementation for this environment (indices, except Safari)
    if (!window._text_highlight_fastest_impl) {
      if ((0, _envDetection.isSafari)()) {
        window._text_highlight_fastest_impl = _regexImplementation.default;
      } else {
        window._text_highlight_fastest_impl = _indicesImplementation.default;
      }
    }

    return window._text_highlight_fastest_impl(value, query, options);
  });


  function findValueAndTransformToStringIfApplicable(params) {
    var value = params[0];
    return Ember.typeOf(value) === 'number' ? value.toString() : value;
  }
});