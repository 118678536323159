define("ember-stripe-elements/services/stripev3", ["exports", "ember-stripe-elements/utils/load-script"], function (_exports, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // As listed at https://stripe.com/docs/stripe-js/reference#the-stripe-object
  var STRIPE_FUNCTIONS = ['elements', 'createToken', 'createSource', 'createPaymentMethod', 'retrieveSource', 'paymentRequest', 'redirectToCheckout', 'retrievePaymentIntent', 'handleCardPayment', 'handleCardAction', 'confirmPaymentIntent', 'handleCardSetup', 'retrieveSetupIntent', 'confirmSetupIntent'];
  var _default = Ember.Service.extend({
    config: null,
    didConfigure: false,
    didLoad: false,
    lazyLoad: Ember.computed.readOnly('config.lazyLoad'),
    mock: Ember.computed.readOnly('config.mock'),
    publishableKey: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('publishableKey', this.get('config.publishableKey'));
      var lazyLoad = this.get('lazyLoad');
      if (!lazyLoad) {
        this.configure();
      }
    },
    load: function load() {
      var _this = this;
      var publishableKey = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      if (publishableKey) {
        this.set('publishableKey', publishableKey);
      }
      var lazyLoad = this.get('lazyLoad');
      var mock = this.get('mock');
      var shouldLoad = lazyLoad && !mock;
      var doLoad = shouldLoad ? (0, _loadScript.default)("https://js.stripe.com/v3/") : Ember.RSVP.resolve();
      return doLoad.then(function () {
        _this.configure();
        _this.set('didLoad', true);
      });
    },
    configure: function configure() {
      var didConfigure = this.get('didConfigure');
      if (!didConfigure) {
        var publishableKey = this.get('publishableKey');
        if (!publishableKey) {
          throw new Ember.Error("stripev3: Missing Stripe key, please set `ENV.stripe.publishableKey` in config.environment.js");
        }
        var stripe = new Stripe(publishableKey);
        var functions = Ember.getProperties(stripe, STRIPE_FUNCTIONS);
        Ember.setProperties(this, functions);
        this.set('didConfigure', true);
      }
    }
  });
  _exports.default = _default;
});