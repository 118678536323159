define('ember-perfect-scroll/components/perfect-scroll/component', ['exports', 'ember-perfect-scroll/components/perfect-scroll/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$,
      _get = Ember.get,
      set = Ember.set,
      run = Ember.run,
      isPresent = Ember.isPresent,
      computed = Ember.computed,
      isEmpty = Ember.isEmpty,
      guidFor = Ember.guidFor;


  // Perfect Scrollbar scroll events
  var psEvents = ['ps-scroll-y', 'ps-scroll-x', 'ps-scroll-up', 'ps-scroll-down', 'ps-scroll-left', 'ps-scroll-right', 'ps-y-reach-start', 'ps-y-reach-end', 'ps-x-reach-start', 'ps-x-reach-end'];

  // Perfect Scrollbar scroll events, scroll value type mapping
  var psEventsScrollValueTypeMapping = {
    'ps-scroll-y': 'scrollTop',
    'ps-scroll-x': 'scrollLeft',
    'ps-scroll-up': 'scrollTop',
    'ps-scroll-down': 'scrollTop',
    'ps-scroll-left': 'scrollLeft',
    'ps-scroll-right': 'scrollLeft',
    'ps-y-reach-start': 'scrollTop',
    'ps-y-reach-end': 'scrollTop',
    'ps-x-reach-start': 'scrollLeft',
    'ps-x-reach-end': 'scrollLeft'
  };

  exports.default = Ember.Component.extend({

    layout: _template.default,

    // Internal id for element
    scrollId: null,

    // Internal perfect-scrollbar
    _perfectScrollbar: null,

    // Perfect scrollbar related settings
    wheelSpeed: 1,
    wheelPropagation: false,
    swipePropagation: true,
    minScrollbarLength: null,
    maxScrollbarLength: null,
    useBothWheelAxes: false,
    useKeyboard: true,
    suppressScrollX: false,
    suppressScrollY: false,
    scrollTop: 0,
    scrollLeft: 0,
    scrollXMarginOffset: 0,
    scrollYMarginOffset: 0,
    includePadding: false,
    theme: 'default',

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      run.schedule('afterRender', function () {
        var element = _this.getElementForPs();

        _this.set('_perfectScrollbar', new window.PerfectScrollbar(element, _this._getOptions()));

        // reflect initial scrollLeft and scrollTop positions to the element
        element.scrollLeft = _this.get('scrollLeft');
        element.scrollTop = _this.get('scrollTop');

        _this.bindEvents();
        _this.triggerLifeCycleAction('initialized');
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var perfectScrollbar = this.get('_perfectScrollbar');

      if (perfectScrollbar) {
        perfectScrollbar.destroy();
        this.set('_perfectScrollbar', null);
      }

      this.unbindEvents();
      this.triggerLifeCycleAction('willBeDestroyed');
    },
    triggerLifeCycleAction: function triggerLifeCycleAction(eventName) {
      var lifeCycleEventOccurred = this.get('lifeCycleEventOccurred') || function () {};
      lifeCycleEventOccurred(this.get('_perfectScrollbar'), eventName);
    },
    getElementForPs: function getElementForPs() {
      return document.getElementById(_get(this, 'eId'));
    },


    eId: computed('scrollId', {
      get: function get() {
        if (isEmpty(_get(this, 'scrollId'))) {
          set(this, 'scrollId', 'perfect-scroll-' + guidFor(this));
        }

        return _get(this, 'scrollId');
      }
    }).readOnly(),

    /**
     * Binds perfect-scrollbar events to function
     * and then calls related events if user gives the action
     */
    bindEvents: function bindEvents() {
      var _this2 = this;

      var self = this;
      var mapping = {};
      var el = this.getElementForPs();

      psEvents.map(function (evt) {
        mapping[evt] = function () {
          self.callEvent(evt, _get(el, psEventsScrollValueTypeMapping[evt]));
        };

        $(el).on(evt, mapping[evt].bind(_this2));
      });

      set(this, 'mapping', mapping);
    },


    /**
     * Calls perfect-scrollbar
     * @param  {String} evt perfect-scrollbar event name
     * @param {Number} value
     */
    callEvent: function callEvent(evt, value) {
      var _this3 = this;

      if (isPresent(_get(this, evt))) {
        run.next(this, function () {
          return _this3.sendAction(evt, value);
        });
      }
    },


    /**
     * Unbinds all event listeners
     */
    unbindEvents: function unbindEvents() {
      var _this4 = this;

      var mapping = _get(this, 'mapping');
      var el = $(document.getElementById(_get(this, 'eId')));

      psEvents.map(function (evt) {
        $(el).off(evt, run.cancel(_this4, mapping[evt].bind(_this4)));
      });
    },
    _getOptions: function _getOptions() {
      return {
        wheelSpeed: _get(this, 'wheelSpeed'),
        wheelPropagation: _get(this, 'wheelPropagation'),
        swipePropagation: _get(this, 'swipePropagation'),
        minScrollbarLength: _get(this, 'minScrollbarLength'),
        maxScrollbarLength: _get(this, 'maxScrollbarLength'),
        useBothWheelAxes: _get(this, 'useBothWheelAxes'),
        useKeyboard: _get(this, 'useKeyboard'),
        suppressScrollX: _get(this, 'suppressScrollX'),
        suppressScrollY: _get(this, 'suppressScrollY'),
        scrollXMarginOffset: _get(this, 'scrollXMarginOffset'),
        scrollYMarginOffset: _get(this, 'scrollYMarginOffset'),
        includePadding: _get(this, 'includePadding'),
        theme: _get(this, 'theme')
      };
    }
  });
});