define("ember-bootstrap/templates/components/bs-form/element/control/radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "45ZfxBPg",
    "block": "{\"symbols\":[\"option\",\"index\",\"id\",\"&default\"],\"statements\":[[4,\"each\",[[23,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"form-check\",[27,\"if\",[[23,[\"inline\"]],\" form-check-inline\"],null]]]],[9],[0,\"\\n\"],[4,\"with\",[[27,\"concat\",[[22,0,[\"elementId\"]],\"-\",[22,2,[]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"input\"],[11,\"class\",\"form-check-input\"],[12,\"id\",[22,3,[]]],[12,\"checked\",[27,\"bs-eq\",[[22,1,[]],[23,[\"value\"]]],null]],[12,\"onclick\",[27,\"action\",[[22,0,[]],[23,[\"onChange\"]],[22,1,[]]],null]],[12,\"name\",[21,\"name\"]],[12,\"required\",[21,\"required\"]],[12,\"disabled\",[21,\"disabled\"]],[12,\"autofocus\",[21,\"autofocus\"]],[12,\"tabindex\",[21,\"tabindex\"]],[12,\"form\",[21,\"form\"]],[12,\"title\",[21,\"title\"]],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n      \"],[7,\"label\"],[12,\"for\",[22,3,[]]],[11,\"class\",\"form-check-label\"],[9],[0,\"\\n\"],[4,\"if\",[[24,4]],null,{\"statements\":[[0,\"          \"],[14,4,[[22,1,[]],[22,2,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"optionLabelPath\"]]],null,{\"statements\":[[0,\"            \"],[1,[27,\"get\",[[22,1,[]],[23,[\"optionLabelPath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[22,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/control/radio.hbs"
    }
  });
  _exports.default = _default;
});