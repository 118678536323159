define("ember-concurrency-decorators/last-value", ["exports", "@ember-decorators/utils/decorator", "@ember-decorators/utils/computed"], function (_exports, _decorator, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = lastValue;
  /**
   * This decorator allows you to alias a property to the result of a task. You can also provide a default value to use before the task has completed.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task } from 'ember-concurrency-decorators';
   * import { lastValue } from 'ember-concurrency-decorators';
   *
   * export default class ExampleComponent extends Component {
   *   @task
   *   someTask = function*() {
   *     // ...
   *   };
   *
   *   @lastValue('someTask')
   *   someTaskValue;
   *
   *   @lastValue('someTask')
   *   someTaskValueWithDefault = 'A default value';
   * }
   * ```
   *
   * @function
   * @param {string} taskName the name of the task to read a value from
   */
  function lastValue(taskName) {
    (false && !(typeof taskName === 'string') && Ember.assert("ember-concurrency-decorators: @lastValue expects a task name as the first parameter.", typeof taskName === 'string'));
    return (0, _decorator.decorator)(function (desc) {
      var initializer = desc.initializer;
      delete desc.initializer;
      return (0, _computed.computedDecorator)(function () {
        return Ember.computed("".concat(taskName, ".lastSuccessful"), function () {
          var lastInstance = Ember.get(this, "".concat(taskName, ".lastSuccessful"));
          if (lastInstance) {
            return Ember.get(lastInstance, 'value');
          }
          if (initializer) {
            return initializer.call(this);
          }
        });
      })(desc);
    });
  }
});