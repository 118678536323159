define('ember-perf-timeline/initializers/ember-perf-timeline', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.renderComponentTimeString = renderComponentTimeString;
  exports.renderOutletTimeString = renderOutletTimeString;
  exports.renderGetComponentDefinitionTimeString = renderGetComponentDefinitionTimeString;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function renderComponentTimeString(payload) {
    return payload.object + ' (Rendering: ' + (payload.initialRender ? 'initial' : 'update') + ')';
  }

  function renderOutletTimeString(payload) {
    return payload.object + ' (Rendering: outlet)';
  }

  function renderGetComponentDefinitionTimeString(payload) {
    return payload.object + ' (Rendering: getComponentDefinition)';
  }

  var HAS_PERFORMANCE_API = false;

  function detectPerformanceApi() {
    return typeof performance !== 'undefined' && typeof performance.mark === 'function' && typeof performance.measure === 'function';
  }

  function startMark(label) {
    if (HAS_PERFORMANCE_API) {
      performance.mark(label + '-start');
    } else {
      console.time(label);
    }
  }

  function endMark(label) {
    if (HAS_PERFORMANCE_API) {
      var _startMark = label + '-start';
      var _endMark = label + '-end';
      performance.mark(_endMark);
      performance.measure(label, _startMark, _endMark);
    } else {
      console.timeEnd(label);
    }
  }

  var hasLocation = typeof self !== 'undefined' && _typeof(self.location) === 'object';
  var shouldActivatePerformanceTracing = hasLocation && /[\?\&]_ember-perf-timeline=true/ig.test(self.location.search);

  if (shouldActivatePerformanceTracing) {
    HAS_PERFORMANCE_API = detectPerformanceApi();

    var EVENT_ID = 0;

    // prevent folks from force-flushing this queue when we are active
    if (HAS_PERFORMANCE_API) {
      performance.clearMeasures = function () {};
      performance.clearMarks = function () {};
    }

    var TriggerMixin = Ember.Mixin.create({
      trigger: function trigger(eventName) {
        var eventId = EVENT_ID++;
        var label = this.toString() + ':' + eventName + ':' + eventId;
        startMark(label);
        var ret = this._super.apply(this, arguments);
        endMark(label);
        return ret;
      }
    });

    Ember.Component.reopen(TriggerMixin);
    Ember.Evented.reopen(TriggerMixin);

    if (requirejs.entries['ember-data/index']) {
      var Model = requirejs('ember-data/index').default.Model;
      Model.reopen(TriggerMixin);
    }

    Ember.subscribe('render.component', {
      before: function $beforeRenderComponent(eventName, time, payload) {
        startMark(renderComponentTimeString(payload));
      },
      after: function $afterRenderComponent(eventName, time, payload) {
        endMark(renderComponentTimeString(payload));
      } });

    Ember.subscribe('render.outlet', {
      before: function $beforeRenderComponent(eventName, time, payload) {
        startMark(renderOutletTimeString(payload));
      },
      after: function $afterRenderComponent(eventName, time, payload) {
        endMark(renderOutletTimeString(payload));
      } });

    Ember.subscribe('render.getComponentDefinition', {
      before: function $beforeRenderComponent(eventName, time, payload) {
        startMark(renderGetComponentDefinitionTimeString(payload));
      },
      after: function $afterRenderComponent(eventName, time, payload) {
        endMark(renderGetComponentDefinitionTimeString(payload));
      } });
  }

  exports.default = {
    name: 'ember-perf-timeline',
    initialize: function initialize() {}
  };
});