define("ember-power-select-with-create/templates/components/power-select-with-create/suggested-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Yf1FygIg",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,[\"option\",\"text\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select-with-create/templates/components/power-select-with-create/suggested-option.hbs"
    }
  });
  _exports.default = _default;
});