define("ember-bootstrap/templates/components/bs-accordion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NlJ5TfmO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"item\",\"change\"],[[27,\"component\",[[23,[\"itemComponent\"]]],[[\"selected\",\"onClick\"],[[23,[\"isSelected\"]],[27,\"action\",[[22,0,[]],\"change\"],null]]]],[27,\"action\",[[22,0,[]],\"change\"],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-accordion.hbs"
    }
  });
  _exports.default = _default;
});