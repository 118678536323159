define('ember-m-image/utils/m-image-fetch-loader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mImageFetchLoader;
  function mImageFetchLoader(init) {

    function blobToBase64(blob) {
      return new Ember.RSVP.Promise(function (resolve, reject) {

        if (Ember.isEmpty(blob)) {
          return resolve();
        }

        var reader = new window.FileReader();
        reader.readAsDataURL(blob);

        reader.addEventListener('load', function () {
          resolve(reader.result);
        });

        reader.addEventListener('error', function () {
          reject();
        });
      });
    }

    return function loader(src) {
      return new Ember.RSVP.Promise(function (resolve, reject) {

        var onError = function onError(error) {
          reject(error);
        };

        var onSuccess = function onSuccess(response) {
          response.blob().then(function (result) {
            blobToBase64(result).then(function (base64ImageSource) {
              resolve(base64ImageSource);
            }).catch(onerror);
          });
        };

        return fetch(src, init || {}).then(function (response) {
          if (response.ok) {
            onSuccess(response);
          } else {
            onError(response.json());
          }
        }).catch(onError);
      });
    };
  }
});