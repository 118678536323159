define("ember-search-flow/templates/components/search-flow/input-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ioEVrcTz",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"input\",null,[[\"class\",\"value\",\"placeholder\",\"focus-out\",\"focus-in\",\"key-down\",\"key-up\",\"enter\"],[\"search-flow_input\",[23,[\"value\"]],[23,[\"placeholder\"]],\"inputBlurred\",\"inputFocused\",\"inputKeyDown\",\"inputKeyUp\",\"inputEntered\"]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"filter\",\"isFocused\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[27,\"search-flow/dropdown\",null,[[\"options\",\"activeOption\",\"isContains\",\"selectOption\"],[[23,[\"availableOptions\"]],[23,[\"activeOption\"]],[23,[\"filter\",\"isContains\"]],[27,\"action\",[[22,0,[]],\"selectOption\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-search-flow/templates/components/search-flow/input-dropdown.hbs"
    }
  });
  _exports.default = _default;
});