define("ember-circle-progress/components/ember-circle-progress", ["exports", "ember-circle-progress/templates/components/ember-circle-progress"], function (exports, _emberCircleProgress) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _emberCircleProgress.default,

    size: 80,
    value: 0.5,
    responsive: false,
    startAngle: -Math.PI,
    reverse: false,
    thickness: "false",
    lineCap: "butt",
    fill: null,
    emptyFill: "rgba(0, 0, 0, .1)",
    animation: null,
    animationStartValue: 0.0,
    textColor: "gray",
    showText: true,

    classNames: ["ember-circle-progress"],
    classNameBindings: ["responsive:ember-circle-progress-responsive"],

    value_update: Ember.observer("value", function () {
      this.$().circleProgress("value", this.get("value"));
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      var size = this.get("size");
      var textColor = this.get("textColor");

      if (!this.get("fill")) {
        this.set("fill", { gradient: ["#3aeabb", "#fdd250"] });
      }

      if (!this.get("animation")) {
        this.set("animation", { duration: 1200 });
      }

      var circle = this.$().circleProgress({
        value: this.get("value"),
        size: size,
        startAngle: this.get("startAngle"),
        reverse: this.get("reverse"),
        thickness: this.get("thickness"),
        lineCap: this.get("lineCap"),
        fill: this.get("fill"),
        emptyFill: this.get("emptyFill"),
        animation: this.get("animation"),
        animationStartValue: this.get("animationStartValue")
      });

      if (this.get("showText")) {
        circle.on("circle-inited", function (event, progress, value) {
          _this.drawText(textColor, size, value);
        });

        circle.on("circle-animation-progress", function (event, progress, value) {
          _this.drawText(textColor, size, value);
        });
      }
    },
    drawText: function drawText(textColor, size, valueStep) {
      var canvas = this.$("canvas");

      if (!canvas) {
        return;
      }

      var ctx = canvas[0].getContext("2d");

      ctx.fillStyle = textColor;
      ctx.font = size / 4 + "px arial";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      var value = Math.round(100 * valueStep) + "%";
      ctx.fillText(value, size / 2, size / 2);
    }
  });
});