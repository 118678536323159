define('ember-remodal/components/er-button', ['exports', 'ember-remodal/templates/components/er-button'], function (exports, _erButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _erButton.default,
    tagName: 'span',

    click: function click() {
      this.sendAction();
    },


    destination: Ember.computed('modalId', {
      get: function get() {
        var modalId = this.get('modalId');

        if (modalId) {
          return 'open-button-' + modalId;
        }
      }
    })
  });
});