define("ember-crumbly/templates/components/bread-crumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "f0GBuika",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"route\",\"linkable\"]]],null,{\"statements\":[[4,\"link-to\",[[23,[\"route\",\"path\"]]],[[\"class\"],[[23,[\"linkClass\"]]]],{\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"      \"],[14,1,[[22,0,[]],[23,[\"route\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[23,[\"route\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"    \"],[14,1,[[22,0,[]],[23,[\"route\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,[\"route\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-crumbly/templates/components/bread-crumb.hbs"
    }
  });
  _exports.default = _default;
});