define("liquid-fire/templates/components/liquid-if", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1hZGsoSe",
    "block": "{\"symbols\":[\"container\",\"valueVersion\",\"valueVersion\",\"&inverse\",\"&default\"],\"statements\":[[4,\"if\",[[23,[\"containerless\"]]],null,{\"statements\":[[0,\"\\n\"],[0,\"\\n\"],[4,\"liquid-versions\",null,[[\"value\",\"matchContext\",\"use\",\"rules\",\"renderWhenFalse\",\"class\"],[[27,\"if\",[[23,[\"inverted\"]],[27,\"if\",[[23,[\"predicate\"]],false,true],null],[27,\"if\",[[23,[\"predicate\"]],true,false],null]],null],[27,\"hash\",null,[[\"helperName\"],[[23,[\"helperName\"]]]]],[23,[\"use\"]],[23,[\"rules\"]],[24,4],[23,[\"class\"]]]],{\"statements\":[[4,\"if\",[[22,3,[]]],null,{\"statements\":[[0,\"      \"],[14,5],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[14,4],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[3]},null]],\"parameters\":[]},{\"statements\":[[4,\"liquid-container\",null,[[\"id\",\"class\",\"growDuration\",\"growPixelsPerSecond\",\"growEasing\",\"shrinkDelay\",\"growDelay\",\"enableGrowth\"],[[23,[\"containerId\"]],[23,[\"class\"]],[23,[\"growDuration\"]],[23,[\"growPixelsPerSecond\"]],[23,[\"growEasing\"]],[23,[\"shrinkDelay\"]],[23,[\"growDelay\"]],[23,[\"enableGrowth\"]]]],{\"statements\":[[4,\"liquid-versions\",null,[[\"value\",\"notify\",\"matchContext\",\"use\",\"rules\",\"renderWhenFalse\"],[[27,\"if\",[[23,[\"inverted\"]],[27,\"if\",[[23,[\"predicate\"]],false,true],null],[27,\"if\",[[23,[\"predicate\"]],true,false],null]],null],[22,1,[]],[27,\"hash\",null,[[\"helperName\"],[[23,[\"helperName\"]]]]],[23,[\"use\"]],[23,[\"rules\"]],[24,4]]],{\"statements\":[[4,\"if\",[[22,2,[]]],null,{\"statements\":[[0,\"        \"],[14,5],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[14,4],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "liquid-fire/templates/components/liquid-if.hbs"
    }
  });
  _exports.default = _default;
});