define("ember-composable-helpers/-private/create-needle-haystack-helper", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createNeedleHaystackHelper;
  var K = function K() {};

  /**
   * Creates a generic Helper class implementation that expects a `needle` and
   * `haystack` as arguments. A `fn` function is required to be passed in
   * that is invoked with the `needle` and `haystack` arguments.
   *
   * @private
   * @param  {Function} fn A function to run against the needle and haystack
   * @return {Any}
   */
  function createNeedleHaystackHelper() {
    var fn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : K;
    return Ember.Helper.extend({
      content: Ember.computed('needle.[]', 'haystack.[]', 'option', function () {
        var needle = Ember.get(this, 'needle');
        var haystack = Ember.get(this, 'haystack');
        var option = Ember.get(this, 'option');
        return fn(needle, haystack, option);
      }).readOnly(),
      compute: function compute(_ref) {
        var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
          needle = _ref2[0],
          option = _ref2[1],
          haystack = _ref2[2];
        if (Ember.isEmpty(haystack)) {
          haystack = option;
          option = null;
        }
        Ember.set(this, 'needle', needle);
        Ember.set(this, 'haystack', haystack);
        Ember.set(this, 'option', option);
        return Ember.get(this, 'content');
      },
      contentDidChange: Ember.observer('content', function () {
        this.recompute();
      })
    });
  }
});