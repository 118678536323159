define("ember-bootstrap/templates/components/bs-form/element/layout/vertical/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6h500LYY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"form-check\"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n  \"],[1,[27,\"component\",[[23,[\"labelComponent\"]]],null],false],[0,\"\\n  \"],[1,[27,\"component\",[[23,[\"errorsComponent\"]]],null],false],[0,\"\\n  \"],[1,[27,\"component\",[[23,[\"helpTextComponent\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/vertical/checkbox.hbs"
    }
  });
  _exports.default = _default;
});