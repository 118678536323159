define('ember-m-image/utils/m-image-dom-loader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mImageDomLoader;
  function mImageDomLoader() {

    var destroy = function destroy(imageContext) {
      var _imageContext = imageContext,
          domElement = _imageContext.domElement,
          eventListeners = _imageContext.eventListeners;

      domElement.removeEventListener('load', eventListeners.load);
      domElement.removeEventListener('error', eventListeners.error);
      domElement = null;
      imageContext = null;
    };

    return function loadImage(src) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var image = new Image(),
            imageContext = {};

        var loadEventListener = function loadEventListener() {
          destroy(imageContext);
          resolve(src);
        },
            errorEventListener = function errorEventListener() {
          destroy(imageContext);
          reject();
        };

        imageContext.domElement = image;
        imageContext.eventListeners = {
          load: loadEventListener,
          error: errorEventListener
        };
        image.addEventListener('load', loadEventListener);
        image.addEventListener('error', errorEventListener);
        image.src = src;
      });
    };
  }
});