define("ember-composable-helpers/helpers/flatten", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.flatten = flatten;
  function flatten(array) {
    if (!Ember.isArray(array)) {
      return array;
    }
    return array.reduce(function (flattened, el) {
      return flattened.concat(flatten(el));
    }, []);
  }
  var _default = Ember.Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        array = _ref2[0];
      Ember.set(this, 'array', array);
      return flatten(array);
    },
    arrayContentDidChange: Ember.observer('array.[]', function () {
      this.recompute();
    })
  });
  _exports.default = _default;
});