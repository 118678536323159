define("ember-composable-helpers/helpers/chunk", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.chunk = chunk;
  _exports.default = void 0;
  var max = Math.max,
    ceil = Math.ceil;
  function chunk(num, array) {
    var integer = parseInt(num, 10);
    var size = max(integer, 0);
    var length = 0;
    if (Ember.isArray(array)) {
      length = Ember.get(array, 'length');
    }
    if (!length || size < 1) {
      return [];
    } else {
      var index = 0;
      var resultIndex = -1;
      var result = new Array(ceil(length / size));
      while (index < length) {
        result[++resultIndex] = array.slice(index, index += size);
      }
      return result;
    }
  }
  var _default = Ember.Helper.extend({
    content: Ember.computed('num', 'array.[]', function () {
      var array = Ember.get(this, 'array');
      var num = Ember.get(this, 'num');
      return chunk(num, array);
    }),
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        num = _ref2[0],
        array = _ref2[1];
      Ember.set(this, 'array', array);
      Ember.set(this, 'num', num);
      return Ember.get(this, 'content');
    },
    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });
  _exports.default = _default;
});