define("@ember-decorators/utils/decorator", ["exports", "@ember-decorators/utils/-private/class-field-descriptor"], function (_exports, _classFieldDescriptor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.decorator = decorator;
  _exports.decoratorWithParams = decoratorWithParams;
  _exports.decoratorWithRequiredParams = decoratorWithRequiredParams;
  function decorator(fn) {
    return function () {
      for (var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++) {
        params[_key] = arguments[_key];
      }
      if ((0, _classFieldDescriptor.isStage2FieldDescriptor)(params)) {
        (false && !(false) && Ember.deprecate('You are using the stage 2 decorator trasforms (@ember-decorators/babel-transforms v3-v5). Ember has officially adopted the stage 1 transforms instead. The stage 2 transforms will not be supported in ember-decorators v6. You can update `ember-cli-babel` to the latest version (at least 7.7.3) and remove @ember-decorators/babel-transforms from your app/addon.', false, {
          id: 'action-deprecation',
          until: '6.0.0'
        }));
        var desc = params[0];
        return fn(desc);
      } else {
        var _desc = (0, _classFieldDescriptor.convertStage1ToStage2)(params);
        _desc = fn(_desc) || _desc;
        if (typeof _desc.finisher === 'function') {
          // Finishers are supposed to run at the end of class finalization,
          // but we don't get that with stage 1 transforms. We have to be careful
          // to make sure that we aren't doing any operations which would change
          // due to timing.
          var target = params[0];
          _desc.finisher(target.prototype ? target : target.constructor);
        }
        if (typeof _desc.initializer === 'function') {
          // Babel 6 / the legacy decorator transform needs the initializer back
          // on the property descriptor/ In case the user has set a new
          // initializer on the member descriptor, we transfer it back to
          // original descriptor.
          _desc.descriptor.initializer = _desc.initializer;
        }
        return _desc.descriptor;
      }
    };
  }

  /**
   * A macro that takes a decorator function and allows it to optionally
   * receive parameters
   *
   * ```js
   * let foo = decoratorWithParams((target, desc, key, params) => {
   *   console.log(params);
   * });
   *
   * class {
   *   @foo bar; // undefined
   *   @foo('bar') baz; // ['bar']
   * }
   * ```
   *
   * @param {Function} fn - decorator function
   */
  function decoratorWithParams(fn) {
    return function () {
      for (var _len2 = arguments.length, params = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        params[_key2] = arguments[_key2];
      }
      // determine if user called as @computed('blah', 'blah') or @computed
      if ((0, _classFieldDescriptor.isDescriptor)(params)) {
        return decorator(fn).apply(void 0, params);
      } else {
        return decorator(function (desc) {
          return fn(desc, params);
        });
      }
    };
  }

  /**
   * A macro that takes a decorator function and requires it to receive
   * parameters:
   *
   * ```js
   * let foo = decoratorWithRequiredParams((target, desc, key, params) => {
   *   console.log(params);
   * });
   *
   * class {
   *   @foo('bar') baz; // ['bar']
   *   @foo bar; // Error
   * }
   * ```
   *
   * @param {Function} fn - decorator function
   */
  function decoratorWithRequiredParams(fn, name) {
    return function () {
      for (var _len3 = arguments.length, params = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        params[_key3] = arguments[_key3];
      }
      (false && !(!(0, _classFieldDescriptor.isDescriptor)(params) && params.length > 0) && Ember.assert("The @".concat(name || fn.name, " decorator requires parameters"), !(0, _classFieldDescriptor.isDescriptor)(params) && params.length > 0));
      return decorator(function (desc) {
        return fn(desc, params);
      });
    };
  }
});