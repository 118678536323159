define("ember-bootstrap/templates/components/bs-modal/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WpZDwPjX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[27,\"hash\",null,[[\"title\",\"close\"],[[27,\"component\",[[23,[\"titleComponent\"]]],null],[27,\"component\",[[23,[\"closeComponent\"]]],[[\"onClick\"],[[27,\"action\",[[22,0,[]],[23,[\"onClose\"]]],null]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[4,\"component\",[[23,[\"titleComponent\"]]],null,{\"statements\":[[1,[21,\"title\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,[\"closeButton\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"component\",[[23,[\"closeComponent\"]]],[[\"onClick\"],[[27,\"action\",[[22,0,[]],[23,[\"onClose\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/header.hbs"
    }
  });
  _exports.default = _default;
});