define("learning/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _routes.default)(function () {
    // Define your engine's route map here
    this.route('course', {
      path: ':slug'
    }, function () {
      this.route('section', {
        path: ':section_slug'
      }, function () {
        this.route('slide', {
          path: ':slide_slug'
        });
      });
    });
  });
  _exports.default = _default;
});