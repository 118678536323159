define("ember-power-select/helpers/ember-power-select-true-string-if-present", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.emberPowerSelectTrueStringIfPresent = emberPowerSelectTrueStringIfPresent;
  function emberPowerSelectTrueStringIfPresent(_ref /* , hash*/) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
      bool = _ref2[0];
    return bool ? 'true' : false;
  }
  var _default = Ember.Helper.helper(emberPowerSelectTrueStringIfPresent);
  _exports.default = _default;
});