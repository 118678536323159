define("ember-bootstrap/templates/components/bs-dropdown/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YVa4Lvra",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"_isOpen\"]]],null,{\"statements\":[[4,\"ember-popper\",null,[[\"id\",\"class\",\"ariaRole\",\"placement\",\"popperTarget\",\"renderInPlace\",\"popperContainer\",\"modifiers\"],[[27,\"concat\",[[23,[\"dropdownElementId\"]],\"__menu\"],null],[27,\"concat\",[\"dropdown-menu \",[23,[\"alignClass\"]],[27,\"if\",[[23,[\"isOpen\"]],\" show\"],null],\" \",[23,[\"class\"]]],null],[23,[\"ariaRole\"]],[23,[\"popperPlacement\"]],[23,[\"toggleElement\"]],[23,[\"_renderInPlace\"]],\"#ember-bootstrap-wormhole\",[23,[\"popperModifiers\"]]]],{\"statements\":[[0,\"    \"],[14,1,[[27,\"hash\",null,[[\"item\",\"link-to\",\"divider\"],[[27,\"component\",[[23,[\"itemComponent\"]]],null],[27,\"component\",[[23,[\"linkToComponent\"]]],null],[27,\"component\",[[23,[\"dividerComponent\"]]],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown/menu.hbs"
    }
  });
  _exports.default = _default;
});