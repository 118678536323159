define("ember-bootstrap/templates/components/bs-accordion/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "VmAiFoTz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"h5\"],[11,\"class\",\"mb-0\"],[9],[0,\"\\n  \"],[7,\"button\"],[12,\"class\",[28,[\"btn btn-link \",[27,\"if\",[[23,[\"disabled\"]],\"disabled\"],null]]]],[12,\"disabled\",[21,\"disabled\"]],[11,\"type\",\"button\"],[9],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-accordion/title.hbs"
    }
  });
  _exports.default = _default;
});