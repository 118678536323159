define('ember-m-image/utils/m-image-ajax-loader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mImageAjaxLoader;
  function mImageAjaxLoader(ajaxOptions) {

    return function loadImage(src) {

      return new Ember.RSVP.Promise(function (resolve, reject) {

        var onSuccess = function onSuccess(imageData) {
          resolve(imageData);
        },
            onError = function onError() {
          reject();
        };

        return Ember.$.ajax(Object.assign({
          type: 'GET',
          url: src,
          mimeType: 'text/plain; charset=x-user-defined'
        }, ajaxOptions || {}, {
          success: onSuccess,
          error: onError
        }));
      });
    };
  }
});